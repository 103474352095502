body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.control-panel {
	font-size: 16px;
	color: white;
	background-color: #731f1f;
	border-radius: 5px;
}

.clickable {
	cursor: pointer;
}

.disabled {
	cursor: not-allowed;
	color: gray;
}

div::-webkit-scrollbar {
	width: 0.3em;
}

div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

div::-webkit-scrollbar-thumb {
	background-color: #731f1f;
	outline: 1px solid #731f1f;
}

body::-webkit-scrollbar {
	width: 0.5em;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
	background-color: #731f1f;
	outline: 1px solid #731f1f;
}