.embla {
	overflow: hidden;
	border-radius: 25px;
}

.embla__container {
	display: flex;
	margin: -10px;
}

.embla__slide {
	background-color: white;
	flex: 0 0 100%;
	min-width: 0;
}

.embla__dot {
	touch-action: manipulation;
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	border-radius: 25px;
	width: 30px;
	height: 8px;
	display: flex;
	align-items: center;
	margin-right: 5px;
	margin-left: 5px;
}

.embla__dots {
	z-index: 1;
	bottom: 10px;
	position: relative;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.embla__dot--selected {
	background-color: #731f1f;
}

.embla__button {
	background-color: transparent;
	touch-action: manipulation;
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
	z-index: 1;
	color: var(--background-site);
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);
	width: 4rem;
	height: 4rem;
}

.embla__button--prev {
	left: 0;
	margin-top: -15%;
	margin-left: -15px;
}

.embla__button--next {
	right: 0;
	margin-top: -15%;
	margin-right: -15px;
}

.embla__button__svg {
	width: 50%;
	height: 50%;
	stroke: #731f1f;
	stroke-width: 10px;
	fill: white;
}