.embla__historia {
	position: relative;
	--slide-spacing: 0.1rem;
	--slide-size: 25%;
	--slide-height: 14rem;
	padding: 0.5rem;
	display: grid;
}

.embla__historia__viewport {
	overflow: hidden;
	margin-left: 40px;
	margin-right: 40px;
}

.embla__historia__container {
	backface-visibility: hidden;
	display: flex;
	touch-action: pan-y;
	/* margin-left: 'calc(var(--slide-spacing) * -1);' */
	margin-left: 0;
}

.embla__historia__slide {
	flex: 0 0 var(--slide-size);
	min-width: 0;
	padding-left: var(--slide-spacing);
	position: relative;
	cursor: pointer;
}

.embla__historia__slide__img {
	display: block;
	height: var(--slide-height);
	width: 100%;
	object-fit: cover;
}

.embla__historia__dot {
	touch-action: manipulation;
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	border-radius: 25px;
	width: 30px;
	height: 8px;
	display: flex;
	align-items: center;
	margin-right: 5px;
	margin-left: 5px;
}

.embla__historia__dots {
	z-index: 1;
	bottom: 10px;
	position: relative;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.embla__historia__dot--selected {
	background-color: #731f1f;
}

.embla__historia__button {
	background-color: transparent;
	touch-action: manipulation;
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
	z-index: 1;
	color: var(--background-site);
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);
	width: 3rem;
	height: 3rem;
}

.embla__historia__button--prev {
	left: 0;
	margin-top: -11%;
	margin-left: -15px;
}

.embla__historia__button--next {
	right: 0;
	margin-top: -11%;
	margin-right: -15px;
}

.embla__historia__button__svg {
	width: 50%;
	height: 50%;
	stroke: #731f1f;
	stroke-width: 10px;
	fill: white;
}

.embla__historia__slide__year {
	position: relative;
	width: 100%;
	height: 50px;
	background-color: lightgray;
	color: black;
	text-align: center;
	line-height: 50px;
	/* para centrar verticalmente el texto */
	transition: background-color 0.3s ease;
	margin-bottom: 15px;
}



.embla__historia__slide.active .embla__historia__slide__year {
	background-color: rgb(128, 37, 40);
	color: white;
	font-weight: bold;
}

.embla__historia__slide.active .embla__historia__slide__year::after {
	content: '';
	position: absolute;
	bottom: -15px;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid rgb(128, 37, 40);
}